import { CSSProperties } from 'react';

export const style: CSSProperties = {
  color: 'rgb(53,110,255)',
  backgroundColor: 'rgb(255 255 255 / 88%)',
  position: 'absolute',
  zIndex: 1000,
  top: '0px',
  bottom: '0px',
  right: '0px',
  left: '0px',
};
